import './app.css';
import StyledFooter from './shared/styledFooter'
import StyledHeader from './shared/styledHeader'
import Home from './screens/home'
import styled from 'styled-components'

function App() {
  return (
    <BoxContainer>
      <StyledHeader/>
    <Home/>
      <StyledFooter/>
    </BoxContainer>
  );
}

const BoxContainer = styled.div`
  width: 100%;
`

export default App;
