const size = {
  laptop4K:'3840px',
  mobileS: '360px',
  mobileL: '425px',
  tabletS: '480px',
  tabletL: '768px',
  laptopS: '1024px',
  laptopL: '1200px'
}

const devices = {
  devicesS: {
    min_mobile: `(min-width: ${size.mobileS})`,
    max_mobile: `(max-width: ${size.mobileS})`,
    min_tablet: `(min-width: ${size.tabletS})`,
    max_tablet: `(max-width: ${size.tabletS})`,
    min_laptop: `(min-width: ${size.laptopS})`,
    max_laptop: `(max-width: ${size.laptopS})`
  },
  devicesL: {
    max_laptop4K: `(max-width: ${size.laptop4K})`,
    min_mobile: `(min-width: ${size.mobileL})`,
    max_mobile: `(max-width: ${size.mobileL})`,
    min_tablet: `(min-width: ${size.tabletL})`,
    max_tablet: `(max-width: ${size.tabletL})`,
    min_laptop: `(min-width: ${size.laptopL})`,
    max_laptop: `(max-width: ${size.laptopL})`
  }
}

export default devices
