import React from 'react';
import styled from 'styled-components'
import devices from '../config/devices'

const StyledFooter = () => {
    return (
        <StyledFooterDisplay></StyledFooterDisplay>
    );
};

const StyledFooterDisplay = styled.div`
  position: fixed;
  min-height: 11vh;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  background-color: #ffffff;
  @media ${devices.devicesL.max_laptop} {
  position: fixed;
  min-height: 12vh;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  background-color: #ffffff;
    }
  @media ${devices.devicesS.max_laptop} {
  position: fixed;
  min-height: 12vh;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  background-color: #ffffff;

`
export default StyledFooter;