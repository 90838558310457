import React from 'react';
import styled from 'styled-components'
import logo from './../assets/logo.svg'

const StyledHeader = () => {
    return (
        <HeaderDisplay>
            <Display>
            <Img src={logo} />
            </Display>
        </HeaderDisplay>
    );
};

const HeaderDisplay = styled.div`
    position: fixed;
    min-height: 6vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  
    box-shadow: 0px 0px 4px grey;
`
const Display = styled.div`
    margin: 20px;
    width: 100px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
`
const Img = styled.img`
    align-items: center;
    justify-content: center;
    display: flex;
    height: 60px;
    width: 60px;
  
`
export default StyledHeader;