import React from 'react'
import styled from 'styled-components'
// import { Butt } from '../components/Buttons/Buttons'
import Buttons from '../components/Buttons/Buttons'

// import tiktok from '../assets/tiktok.svg'
import web from '../assets/web.svg'
import insta from '../assets/insta.svg'
import facebook from '../assets/fb.svg'
import pin from '../assets/pin.svg'
import devices from '../config/devices'
import link from '../assets/link.svg'
import Title from '../components/title/index'



const Home = () => {
  
 

    const handleClickFacebook = () => {
        window.open("https://www.facebook.com/profile.php?id=100073167129059");
    
      };

      const handleClickInstagram = () => {
        window.open("https://www.instagram.com/pow_konwu/");
      };
      const handleClickPinterest = () => {
        window.open("https://www.pinterest.fr/");
      };
   
      const handleClickWeb = () => {
        window.open("https://powkonwu.com/");
      };  
      const handleClickLinkedin = () => {
        window.open("https://www.linkedin.com/company/pow-konwu/");
      };  
      return (
          <BoxContainerHome>  
            <TitleHome>
                <Displ>
                <TitleP>POW KONWU</TitleP>
                </Displ>
                <Title title='Bienvenue sur notre page de redirection !'></Title>
                <Title title='Nos plateformes'></Title>
            </TitleHome>
           
            <DisplayBoxButton>
              <ButtonDisplay onClick={handleClickWeb}>
                  <Buttons title='POWKONWU' img={web} onClick={handleClickWeb} backgroundColor='red'></Buttons>
              </ButtonDisplay>
            
                <ButtonDisplay onClick={handleClickFacebook}>
                    <Buttons title='FACEBOOK' img={facebook} backgroundColor="green"></Buttons>
                </ButtonDisplay>

                <ButtonDisplay onClick={handleClickInstagram}>
                    <Buttons title='INSTAGRAM' img={insta} ></Buttons>
                </ButtonDisplay>
                <ButtonDisplay onClick={handleClickPinterest} >
                    <Buttons title='PINTEREST' img={pin} ></Buttons>
                </ButtonDisplay> 
               <ButtonDisplay onClick={handleClickLinkedin}>
                    <Buttons title='LINKEDIN' img={link} ></Buttons>
                </ButtonDisplay> 
                {/* <ButtonDisplay>
                    <Buttons title='Tiktok' img={tiktok} ></Buttons>
                </ButtonDisplay> */}
                {/* <ButtonDisplay>
                    <Buttons title='Youtube' img={youtube} onClick={handleClickTikTok}></Buttons>
                </ButtonDisplay> */}
            </DisplayBoxButton>

            <Tdb>
                <Text>Bonne journée :)</Text>
                
            </Tdb>
        </BoxContainerHome>
     
    );
};     


// Background fully responsif
const BoxContainerHome = styled.div`
    width: 100%;
    height: 89vh;
    align-items: center;
    justify-content: center;
    background-color: #fbfcfd;
    @media ${devices.devicesL.max_laptop} {
    width: 100%;
    min-height: 40em;
    height: 88vh;
    justify-content: center;
    background-color: #fbfcfd;
    }
    @media ${devices.devicesS.max_laptop} {
    width: 100%;
    min-height: 40em;
    height: 88vh;
    justify-content: center;
    background-color: #fbfcfd;
    }
`
// affichage emplacement Title 
const TitleHome = styled.div`
    width: 100%;
    height: 15em;
    justify-content: center;
    align-items: center;
    
@media ${devices.devicesS.max_laptop} {
    width: 100%;
    justify-content: center;
    align-items: center;
    
 
    }
`
const Displ = styled.div`
    width: 100%;
    display: flex;
    transform: translateY(150px);
    justify-content: center;
    @media ${devices.devicesL.max_laptop} {
    width: 100%;
    height: 4em;
    display: flex;
    transform: translateY(155px);
    justify-content: center;
    }

`
const TitleP = styled.div`
font-weight: medium;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    @media ${devices.devicesL.max_laptop} {
    text-align: center;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    }

`
// affichage emplacement text bas de page
const Tdb = styled.div`
    align-items: center;
    justify-content: center;
    transform: translateY(200px);
    @media ${devices.devicesS.max_laptop} {
        transform: translateY(150px)
        }
    @media ${devices.devicesL.max_laptop} {
        transform: translateY(250px)
  
    }
`

// text en bas de la page 
const Text = styled.h4`
    width: 100%;
    text-align: center;
    font-weight: lighter;
`

// affichage boutons responsifs
const DisplayBoxButton = styled.div`
    width: 100%;
    transform: translateY(100px);
    height: 5em;
    display: flex;
    align-items: center;
    justify-content: center;
 
    @media ${devices.devicesS.max_laptop} {
    margin-top: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    }
`

//disposition de mes bouttons
const ButtonDisplay = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export default Home;