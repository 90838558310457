/* eslint-disable no-use-before-define */
import React from 'react';
import styled from 'styled-components'
import PropTypes from 'prop-types'
import devices from '../../../config/devices'
import { motion} from 'framer-motion/dist/framer-motion'



const Buttons = props => {

    const buto = {
        rest: { scale: 1 },
        hover: { scale: 1.1 },
        pressed: { scale: 0.98 }
      };

    return (
     <Center>
        <motion.bu
            className="refresh"
            variants={buto}
            initial="rest"
            whileHover="hover"
            whileTap="pressed"
            style={{ width: '100%' }}
        >
            <Button>
                <D>
                    <Img src={props.img}/>
                    <Title>{props.title}</Title>
                </D>
            </Button>
     </motion.bu> 
     </Center>
       
    );
};


const Center = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFF;
`

const D = styled.div`
    width: 100%;
    height:  7em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5em;
    @media ${devices.devicesL.max_laptop} {
    width: 30%;
    height:  1em;
    align-items: center;
    justify-content: start;
    }
`
const Button = styled(motion.button)`
    height: 5em;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 1px;
    justify-content: center;
    color: white;
    outline: none;
    border: none;
    /* background-color: #fff; */
    background-color: ${props => props.backgroundColor || "#fff"};
    &:hover {
      background-color: #ddeee3;
    }
`


Buttons.propTypes = {
    title: PropTypes.string,
    img: PropTypes.string,
    backgroundColor: PropTypes.string
}

const Img = styled.img`
    height: 50px;
    width: 50px;
    display: flex;
    margin-right: 1em;
    justify-content: center;
    flex-direction: column;
    align-items: center;
` 
const Title = styled.h1`
    color: black;
    font-size: 13px;
    font-weight: normal;
    align-items: center;
    justify-content: flex-start;
  @media ${devices.devicesL.max_laptop} {
    color: black;
    font-size: 13px;
    font-weight: normal;
    align-items: center;
    justify-content: flex-start;
    }
  @media ${devices.devicesS.max_laptop} {
    color: black;
    font-size: 13px;
    font-weight: normal;
    align-items: center;
    justify-content: flex-start;
`


export default Buttons;
/* export const Butt = () =><Buttons animate={{ scale: 0.1}} />; */