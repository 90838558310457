import React from 'react';
import styled from 'styled-components';
import devices from '../../config/devices';
import PropTypes from 'prop-types'

const Title = props => {
    return (
        <Display>
             <TitleHome>{props.title}</TitleHome>  
        </Display>
    );
};

Title.propTypes = {
    title: PropTypes.string
  }
const Display = styled.div`
    width: 100%;
    display: flex;
    transform: translateY(150px);
    justify-content: center;
    @media ${devices.devicesL.max_laptop} {
    width: 100%;
    height: 4em;
    display: flex;
    transform: translateY(155px);
    justify-content: center;
    }
    
  
`

const TitleHome = styled.h4`
    font-weight: lighter;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  
    @media ${devices.devicesL.max_laptop} {
    text-align: center;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    
`
export default Title;